import { action, payload } from "ts-action";
import * as constants from "../../constants";
import { IUser } from "../../types";

export const getWatchers = action(
  constants.getWatchersAction.requested,
  payload
);
export const getWatchersSuccess = action(
  constants.getWatchersAction.fulfilled,
  payload<IUser[]>()
);
export const getWatchersFailure = action(
  constants.getWatchersAction.rejected,
  payload<Error>()
);
