import { payload } from "ts-action";
import * as constants from "../../constants";
import { IAddWatchers } from "../../types";
import { optAction, optReaction } from "./lib";
import { COMMIT, REVERT } from "redux-optimistic-ui";

export const addWatchers = optAction(
  constants.addWatchersAction.requested,
  payload<IAddWatchers>()
);
export const adddWatchersSuccess = optReaction(
  constants.addWatchersAction.fulfilled,
  payload<IAddWatchers>(),
  COMMIT
);
export const addWatchersFailure = optReaction(
  constants.addWatchersAction.rejected,
  payload<Error>(),
  REVERT
);
