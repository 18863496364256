import { reducer, on } from "ts-action";
import { withLoadingReducer, ILoadingState } from "./withLoadingState";
import { getWatchersAction } from "../../constants";
import { IAddWatchers } from "../../types";
import { addWatchers } from "../actions/addWatchersAction";
import { getWatchersSuccess } from "../actions/getWatchers";
import { optimistic, OptimisticState } from "redux-optimistic-ui";
import { Reducer } from "redux";
interface IState {
  watchers: Array<string | number>;
  watchersById: { [x: string]: IAddWatchers };
}
const initialState: IState = {
  watchers: [],
  watchersById: {},
};

export const watchersReducer = optimistic(
  withLoadingReducer(
    reducer<IState>(
      [
        on(addWatchers, (state, { meta, payload }) => ({
          ...state,
          watchers: [meta.optimistic.id, ...state.watchers],
          watchersById: {
            ...state.watchersById,
            [meta.optimistic.id]: payload,
          },
        })),
        on(getWatchersSuccess, (state, { payload }) => ({
          ...state,
          watchers: payload.map((a) => a.id),
          watchersById: payload.reduce(
            (acc, user) => ({ ...acc, [user.id]: user }),
            {}
          ),
        })),
      ],
      initialState
    ),
    getWatchersAction
  )
) as Reducer<OptimisticState<IState & ILoadingState>>;
