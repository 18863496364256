import React from "react";
import { hot } from "react-hot-loader";
import Users from "./Users";
import Watcher from "./Users/UserList";
import { useSelect } from "../../hooks/useSelect";
import { useDispatch } from "react-redux";
import { getWatchers } from "../../redux-store/actions/getWatchers";
import { getCountries } from "../../redux-store/actions/get-countries-actions";

const WatchTower: React.FC = () => {
  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(getWatchers());
    dispatch(getCountries());
  }, []);
  const { watchers, watchersById } = useSelect(
    (state) => state.watchersReducer
  );
  const TheGreatWatchers = watchers.map((wat) => (
    <Watcher key={wat} data={watchersById[wat]} />
  ));
  return (
    <div>
      <Users />
      {TheGreatWatchers}
    </div>
  );
};

export default hot(module)(WatchTower);
