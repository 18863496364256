import { payload, action } from "ts-action";

import {
  ICreateUniversalPromoCode,
  IPromotionStores,
  ICreatePromoCode,
  IEditPromoCode,
} from "../../types";
import { optAction, optReaction } from "../../../../redux-store/actions/lib";
import { COMMIT, REVERT } from "redux-optimistic-ui";
import {
  createUniversalPromoCodeAction,
  getPromotionStoresAction,
  clearCurrentPromotionStoreAction,
  editPromoCodeAction,
  createPromoCodeAction,
  clearPrevStateAction,
} from "./constants";
import { ISinglePromoCode } from "../../../../constants";

export const createPromoCode = action(
  createPromoCodeAction.requested,
  payload<FormData>()
);
export const createPromoCodeSuccess = action(
  createPromoCodeAction.fulfilled,
  payload<ISinglePromoCode>()
);
export const createPromoCodeFailure = action(
  createPromoCodeAction.rejected,
  payload<ICreatePromoCode>()
);

export const createUniversalPromoCode = optAction(
  createUniversalPromoCodeAction.requested,
  payload<ICreateUniversalPromoCode>()
);
export const createUniversalPromoCodeSuccess = action(
  createUniversalPromoCodeAction.fulfilled,
  payload<ISinglePromoCode>()
);
export const createUniversalPromoCodeFailure = action(
  createUniversalPromoCodeAction.rejected,
  payload<ICreateUniversalPromoCode>()
);

export const editPromoCode = optAction(
  editPromoCodeAction.requested,
  payload<{
    data: IEditPromoCode;
    id: string;
    reactivate?: boolean;
    end_time?: number;
  }>()
);
export const editPromoCodeSuccess = optReaction(
  editPromoCodeAction.fulfilled,
  payload<{
    data: IEditPromoCode;
    id: string;
    reactivate?: boolean;
    end_time?: number;
  }>(),
  COMMIT
);
export const editPromoCodeFailure = optReaction(
  editPromoCodeAction.rejected,
  payload<IEditPromoCode>(),
  REVERT
);

export const getPromotionStores = action(
  getPromotionStoresAction.requested,
  payload<string>()
);
export const getPromotionStoresSuccess = action(
  getPromotionStoresAction.fulfilled,
  payload<IPromotionStores[]>()
);

export const getPromotionStoresFailure = action(
  getPromotionStoresAction.rejected,
  payload<Error>()
);

export const clearCurrentPromotionStore = action(
  clearCurrentPromotionStoreAction.requested
);

export const clearPrevState = action(clearPrevStateAction);
